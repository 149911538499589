import React from 'react';
import { useTranslation } from 'react-i18next';
import { BlokkieTypography, blokkieTheme } from '@tikkie/blokkie';
import { BlokkieGifContainer } from '../../Gif/BlokkieGifContainer';
import { CenterWrapper } from '../styledComponents';
import Title from '../Title';

export default function WaitingScreen() {
  const { t } = useTranslation();

  return (
    <CenterWrapper>
      <BlokkieGifContainer
        source="/hardworking-cat.mp4"
        style={{
          marginTop: blokkieTheme.spacing(4),
          marginBottom: blokkieTheme.spacing(),
        }}
      />
      <Title data-test-id="terms-waiting-header">
        {t('onboarding.terms.waiting.header')}
      </Title>
      <BlokkieTypography
        variant="pl500"
        style={{
          textAlign: 'center',
        }}
      >
        {t('onboarding.terms.waiting.text')}
      </BlokkieTypography>
    </CenterWrapper>
  );
}
