import React from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { blokkieTheme, BlokkieTypography } from '@tikkie/blokkie';
import { useSelector } from 'react-redux';
import { isMobile } from 'react-device-detect';
import clsx from 'clsx';
import {
  OnboardingParagraph,
  OnboardingParagraphHeader,
} from '../styledComponents';
import { selectProductType } from '../../../containers/OnboardingKYCContainer/selectors';
import { productTypeToLokaliseTag } from '../../../containers/OnboardingKYCContainer/config';

function CostRectangles(): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation();
  const productType = useSelector(selectProductType);

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const productTag = productTypeToLokaliseTag.get(productType)!;
  return (
    <>
      <BlokkieTypography variant="pl500">
        {t(`onboarding.costs.${productTag}.description`)}
      </BlokkieTypography>
      <div className={classes.costBlockColumn}>
        <div
          className={[
            classes.costBlockRectangle,
            classes.costBlockRangeFree,
          ].join(' ')}
        >
          <div className={classes.costBlockTransactions}>
            <BlokkieTypography
              variant="ps700"
              color={blokkieTheme.colors.transparent.white['70']}
            >
              {t(`onboarding.costs.${productTag}.range0`)}
            </BlokkieTypography>
          </div>
          <div className={classes.costBlockPrice}>
            <BlokkieTypography
              variant={isMobile ? 'h3' : 'h1'}
              color={blokkieTheme.colors.grey.white}
            >
              {t(`onboarding.costs.free`)}
            </BlokkieTypography>
          </div>
        </div>
        <div
          className={clsx(
            classes.costBlockRectangle,
            classes.costBlockRangePaid,
          )}
        >
          <div className={classes.costBlockTransactions}>
            <BlokkieTypography
              variant="ps700"
              color={blokkieTheme.colors.transparent.white['70']}
            >
              {t(`onboarding.costs.${productTag}.range1`)}
            </BlokkieTypography>
          </div>
          <div className={classes.costBlockPrice}>
            <BlokkieTypography
              variant={isMobile ? 'h3' : 'h1'}
              color={blokkieTheme.colors.grey.white}
            >
              {t(`onboarding.costs.${productTag}.range1Cost`)}
            </BlokkieTypography>
          </div>
          <BlokkieTypography
            variant="ps700"
            color={blokkieTheme.colors.transparent.white['70']}
          >
            {t(`onboarding.costs.perTikkie`)}
          </BlokkieTypography>
        </div>
        <div
          className={[
            classes.costBlockRectangle,
            classes.costBlockRangePaid,
          ].join(' ')}
        >
          <div className={classes.costBlockTransactions}>
            <BlokkieTypography
              variant="ps700"
              color={blokkieTheme.colors.transparent.white['70']}
            >
              {t(`onboarding.costs.${productTag}.range2`)}
            </BlokkieTypography>
          </div>
          <div className={classes.costBlockPrice}>
            <BlokkieTypography
              variant={isMobile ? 'h3' : 'h1'}
              color={blokkieTheme.colors.grey.white}
            >
              {t(`onboarding.costs.${productTag}.range2Cost`)}
            </BlokkieTypography>
          </div>
          <BlokkieTypography
            variant="ps700"
            color={blokkieTheme.colors.transparent.white['70']}
          >
            {t(`onboarding.costs.perTikkie`)}
          </BlokkieTypography>
        </div>
        <div
          className={[
            classes.costBlockRectangle,
            classes.costBlockRangePaid,
          ].join(' ')}
        >
          <div className={classes.costBlockTransactions}>
            <BlokkieTypography
              variant="ps700"
              color={blokkieTheme.colors.transparent.white['70']}
            >
              {t(`onboarding.costs.${productTag}.range3`)}
            </BlokkieTypography>
          </div>
          <div className={classes.costBlockPrice}>
            <BlokkieTypography
              variant={isMobile ? 'h3' : 'h1'}
              color={blokkieTheme.colors.grey.white}
            >
              {t(`onboarding.costs.${productTag}.range3Cost`)}
            </BlokkieTypography>
          </div>
          <BlokkieTypography
            variant="ps700"
            color={blokkieTheme.colors.transparent.white['70']}
          >
            {t(`onboarding.costs.perTikkie`)}
          </BlokkieTypography>
        </div>
      </div>
      {productTag === 'business' && (
        <>
          <OnboardingParagraphHeader>
            {t(`onboarding.costs.headerSms`)}
          </OnboardingParagraphHeader>
          <OnboardingParagraph>
            {t(`onboarding.costs.smsText`)}
          </OnboardingParagraph>
        </>
      )}
      <OnboardingParagraph>
        {t(
          productTag === 'business'
            ? 'onboarding.costs.btwNotice'
            : 'onboarding.costs.btwNoticeForeignTrxFee',
        )}
      </OnboardingParagraph>
    </>
  );
}

const useStyles = createUseStyles({
  costBlockColumn: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: blokkieTheme.spacing(2),
    marginBottom: blokkieTheme.spacing(3),
    gap: blokkieTheme.spacing(),
  },
  costBlockRectangle: {
    minHeight: 55,
    borderRadius: blokkieTheme.spacing(2),
    paddingLeft: blokkieTheme.spacing(2),
    paddingRight: blokkieTheme.spacing(2),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  costBlockRangeFree: {
    background: blokkieTheme.colors.secondary.lightPurple,
  },
  costBlockRangePaid: {
    background: blokkieTheme.colors.secondary.darkPurple,
  },
  costBlockTransactions: {
    width: '55%',
  },
  costBlockPrice: {
    flexDirection: 'column',
    marginRight: blokkieTheme.spacing(),
    [blokkieTheme.mediaQueries.allPhone]: {
      fontSize: '22px',
    },
  },
});

export default CostRectangles;
