import React from 'react';
import {
  BlokkieIconButton,
  IconVariantType,
  blokkieTheme,
} from '@tikkie/blokkie';
import { createUseStyles } from 'react-jss';

interface HeaderIconButton {
  icon: IconVariantType;
  onClick?: () => void;
}

interface IProps {
  clickLeft?: () => void;
  clickRight?: () => void;
  noMarginBottom?: boolean;
}

/*
 *  This component is solely used as a header component inside the upper part
 *  of the right panel during the KYC onboarding. Standard icons are 'arrowLeft'
 *  and 'questionMarkCircled'. Use ButtonHeader for custom buttons.
 */
function NavigationHeader({
  clickLeft,
  clickRight,
  noMarginBottom = false,
}: IProps): JSX.Element {
  const classes = useStyles({ clickLeft, clickRight, noMarginBottom });
  const iconButtons: HeaderIconButton[] = [
    { icon: 'arrowLeft', onClick: clickLeft },
    { icon: 'questionMarkCircled', onClick: clickRight },
  ];

  const shownIconButtons = iconButtons.filter((button) => button.onClick);

  return (
    <div className={classes.container}>
      {shownIconButtons.map(({ icon, onClick }) => (
        <BlokkieIconButton
          data-test-id={`nav-header-${icon}-button-test-id`}
          key={icon}
          variant={icon}
          onClick={() => onClick!()}
        />
      ))}
    </div>
  );
}

const useStyles = createUseStyles({
  container: ({ clickLeft, clickRight, noMarginBottom }: IProps) => ({
    display: 'flex',
    marginLeft: -blokkieTheme.spacing(1.5),
    marginRight: -blokkieTheme.spacing(1.5),
    marginBottom: noMarginBottom ? 0 : blokkieTheme.spacing(2),
    justifyContent: clickRight && !clickLeft ? 'flex-end' : 'space-between',
  }),
});

export default NavigationHeader;
